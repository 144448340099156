import { Flex } from "@radix-ui/themes";
import Link from "next/link";
import { Image } from "react-datocms";

export function GuidesGrid({ datoGuides }) {
    const guides = datoGuides;
    const groupings = guides.map((x) => x.grouping);
    const uniqueGroupings = groupings.filter((v, i, a) => a.indexOf(v) === i);
    uniqueGroupings.sort((a, b) => {
        if (a === "balances") {
            return -1;
        }
        if (b === "balances") {
            return 1;
        }
        if (a === "other") {
            return 1;
        }
        if (b === "other") {
            return -1;
        }
        return 0;
    });

    const nameMapping = {
        "balances": "Token Balances",
        "transactions": "Transactions",
        "nft": "NFTs",
        "increment": "Increment",
        "account abstraction": "Account Abstraction",
        "other": "Other"
    };

    return <>
        {
            uniqueGroupings.map((x, i) => {
                if (x === "increment") {
                    return null;
                }
                return <div className="card__container" key={i}>
                    <Flex direction="column" gap="3" mb="5">
                        <h2>{nameMapping[x] || x}</h2>
                        <div className="cards">
                            {
                                guides.filter((y) => y.grouping === x).map((y, j) => {
                                    return <Link className="guide__card" key={j + y.slug} href={`/docs/unified-api/guides/${y.slug}/`}>
                                        <h3>{y.title}</h3>
                                        {y?.bannerimagegraphic?.responsiveImage && <div className="image-banner">
                                            <Image usePlaceholder={false} data={y.bannerimagegraphic.responsiveImage}/></div>}
                                    </Link>;
                                })
                            }
                        </div>
                    </Flex>
                </div>;
            })
        }
        <style jsx>{`
            .heading {
                text-transform: capitalize;
            }
            .badge {
                color: var(--pink);
                font-size: var(--font-size-2);
            }
            .card__container {
                max-width: calc(100vw - 240px);
            }
            :global(.guide__card) {
                padding: 1.5rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                border-radius: var(--border-radius);
                overflow: hidden;
                min-width: 18.25rem;
                flex: 0 1;
                text-decoration: none;
                transition: 0.2s;
                color: var(--text);
                border: 1px solid var(--code-border);
                justify-content: space-between;
            }

            :global(.guide__card .image-banner) {
            }

            :global(.guide__card:first-of-type), h2 {
                margin-left: 4rem;
            }

            :global(.guide__card:last-of-type), h2 {
                margin-right: 4rem;
            }

            :global(.guide__card:last-of-type), h2 {
                margin-right: 0rem;
            }


            

           

            h2 {
                margin-bottom: -2rem;
            }

            .small-icon {
                margin-left: auto;
                margin-top: 0.6rem;
                padding-left: 1rem;
            }

            .place-items-center {
                place-items: center;
                justify-content: space-between;
            }

            :global(.guide__card:hover) {
                box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
            }

            .cards {
                display: flex;
                gap: 1.5rem;
                padding-bottom: 3rem;
                padding-top: 2.5rem;
                -ms-overflow-style: none;
                scrollbar-width: none;
                overflow-y: scroll;
            }

        

            .cards::-webkit-scrollbar {
                display: none;
            }

            h3 {
                margin-top: 0;
                font-weight: var(--font-weight-light);
                font-size: var(--font-size-4);
                line-height: var(--line-height-4);
                color: var(--text);
            }

            @media screen and (max-width: 767px) {
                :global(.card__container) {
                    max-width: 100vw !important;
                }
                .cards {
                    flex-direction: column;
                }
               
           
                
                :global(.guide__card) {
                    justify-content: flex-start;
                    margin-left: 0 !important;
                }
                :global(.guide__card) {
                    justify-content: flex-start;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
                h2 {
                    margin-left: 1rem;
                }
                .cards {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
               
            }
            
        `}</style>
    </>;
}
