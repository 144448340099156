import { Button } from "../Button";
import { SocialProofWrapper, SocialProofTicker } from "./SocialProof";
import { GradientDivider, GradientDividerLeft, GradientDividerTop } from "../GradientDivider";
import { HugeCTACTA, HugeCTATitle, HugeCTAWrapper, HugeCTACtas, HugeCTASubtitle } from "./HugeCTA";
import { FeaturesWrapper, FeatureHighlightTitle, FeatureHighlightSubtitle, FeatureHighlightImage, FeatureHighlights, FeatureHighlight, FeatureHighlightContent, FeaturesCard, FeaturesCards, FeaturesCardDescription, FeaturesCardTitle } from "./Features";
import { HeroWrapper, HeroBackgroundVideo, HeroContentFull , HeroTitle, HeroCtas, HeroCTA, HeroSubtitle, HeroBackgroundImage} from "./Hero";
import { Icon } from "../Icon";
import { OurApproachTitle } from "./OurApproach";
import { TestimonialWrapper, TestimonialCarouselWrapper, TestimonialLogo, TestimonialContent, TestimonialQuote, TestimonyName, TestimonyPosition } from "./Testimonial";
import { NumberWrapper, NumberContent, NumberCard, NumberText, NumberCardTitle } from "./Number";
import { rudderStackLocation } from "../Track";
import React from "react";
import heroBg from "../../public/static/covalent-backgrounds/homepage_v3_shot2-min.png";
import ParallaxHero from "../ParallaxHero";
import { GuidesGrid } from "../GuidesGrid";
import { Flex, Grid } from "@radix-ui/themes";

export const HomePage = ({ ecosystem, content, guides}) => {
    const getSection = (slug) => {
        return content.content.filter((section) => section.section === slug)[0];
    };
    const hero = getSection("hero");
    const whatCanYouBuild = getSection("what-can-you-build");
    const numbers = getSection("numbers");
    const testimonialsData = require("../../data/dato-testimonials.json");
    const testimonials = testimonialsData.data.allTestimonial2s;

    React.useEffect(() => {
        rudderStackLocation();
    }, []);

    return <div className="landing-page">
        <ParallaxHero 
            imageUrl={""} 
            videoCarousel={
                [hero.background]
            }
        >
            <HeroWrapper dark size="lg">
                <GradientDivider height={"100vh"}/>
                <div className="div-round-rev"/>
                <div className="static-bg">
                    <HeroBackgroundImage  mobile={true} image={heroBg} />
                </div>
                <HeroContentFull>
                    <HeroTitle center={true} animateIn={true} noGradient>
                        {hero.title}
                    </HeroTitle>
                    <HeroSubtitle center={true} animateIn={true}>
                        {hero.subtitle}
                    </HeroSubtitle>
                    <HeroCtas center={true} animateIn={true}>
                        <HeroCTA><Button href="/platform/auth/register/"><div className="flex">Signup for a free API key <div className="icon"><Icon color={"#fff"} icon="chevron-right" size="0.7" /></div></div></Button></HeroCTA>
                        <HeroCTA><Button secondary="true" href="/docs/unified-api/">Read Docs</Button></HeroCTA>
                    </HeroCtas>
                </HeroContentFull>
            </HeroWrapper>
            <SocialProofWrapper >
                <SocialProofTicker ecosystem={ecosystem} />
            </SocialProofWrapper>
            <FeaturesWrapper >
                <FeatureHighlights>
                    {whatCanYouBuild.list.filter((item) => item.featured).map((build, i) => <FeatureHighlight key={i}>
                        <FeatureHighlightContent>
                            <FeatureHighlightTitle>
                                {build.title}
                            </FeatureHighlightTitle>
                            <FeatureHighlightSubtitle>
                                {build.description}
                            </FeatureHighlightSubtitle>
                        </FeatureHighlightContent>
                        <FeatureHighlightImage src={build.image} />
                    </FeatureHighlight>)}
                </FeatureHighlights>
            </FeaturesWrapper>
            <NumberWrapper >
                <GradientDivider />
                <GradientDividerTop />
                <NumberContent>
                    <div className="number-side">
                        {
                            numbers.list.map((number) => {
                                return <NumberCard key={number.title}>
                                    <NumberText>
                                        <h2>{number.value}</h2>
                                        <NumberCardTitle>
                                            {number.title}
                                        </NumberCardTitle>
                                    </NumberText>
                                </NumberCard>;
                            })
                        }
                    </div>
                </NumberContent>
            </NumberWrapper>
            <FeaturesWrapper >
                <FeaturesCards grid={4}>
                    {whatCanYouBuild.list.filter((item) => !item.featured).map((build, i) => <FeaturesCard key={i}>
                        <FeaturesCardTitle>
                            {build.title}
                        </FeaturesCardTitle>
                        <FeaturesCardDescription>
                            {build.description}
                        </FeaturesCardDescription>
                    </FeaturesCard>)}
                </FeaturesCards>
            </FeaturesWrapper>
            <HeroWrapper>
                <HeroBackgroundVideo video={"https://www.datocms-assets.com/86369/1697821375-homepage_200_blockchain.mp4"} />
                <GradientDivider height={"100vh"}/>
                <GradientDividerLeft />
                <GradientDividerTop height={"100vh"}/>
                <div className="div-round-rev"/>
                <div className="div-round"/>
                <HeroContentFull>
                    <HeroTitle header="h2">
                        The Broadest Blockchain Coverage in Web3
                    </HeroTitle>
                    <div className="spacer"/>
                    <HeroCtas>
                        <HeroCTA><Button href="/platform/auth/register/">Signup for a free API key <Icon color={"#fff"} icon="chevron-right" size="0.7" /></Button></HeroCTA>
                        <HeroCTA><Button secondary="true" href="/docs/unified-api/">Read Docs</Button></HeroCTA>
                    </HeroCtas>
                </HeroContentFull>
            </HeroWrapper>
            <TestimonialWrapper>
                <Grid columns={{lg: "2", sm: "1", xs: "1"}} gapX="8">
                    {testimonials.filter(o => o.product === "api").map((o, i) => {
                        return <TestimonialCarouselWrapper key={i}>
                            <TestimonialContent>
                                <TestimonialQuote>{o.quote}</TestimonialQuote>
                                <Flex gap="4" align="center" >
                                    <TestimonialLogo img={o.organization.imgwhite.responsiveImage} />
                                    <div>
                                        <TestimonyName>{o.name}</TestimonyName>
                                        <TestimonyPosition>{o.title}</TestimonyPosition>
                                    </div>
                                </Flex>
                            </TestimonialContent>
                        </TestimonialCarouselWrapper>;
                    })}
                </Grid>
            </TestimonialWrapper>
            <HugeCTAWrapper >
                <GradientDividerTop/>
                <GradientDivider/>
                <HugeCTATitle>
                    Get started with GoldRush
                </HugeCTATitle>
                <HugeCTASubtitle>
                    {content.meta.description}
                </HugeCTASubtitle>
                <HugeCTACtas>
                    <HugeCTACTA key="signup"><Button href="/platform/auth/register/">Signup for a free API key <Icon color="#fff" icon="chevron-right" size="0.7" /></Button></HugeCTACTA>
                    <HugeCTACTA key="demo"><Button href="email:support@covalenthq.com" secondary="true">Speak to an expert</Button></HugeCTACTA>
                </HugeCTACtas>
            </HugeCTAWrapper> 
            <FeaturesWrapper >
                <OurApproachTitle>
                        Latest Guides
                </OurApproachTitle>
                <GuidesGrid datoGuides={guides} />
            </FeaturesWrapper>
        </ParallaxHero>
        <style jsx>{`
        .gradient-text {
            background: linear-gradient(90deg, var(--pink), var(--teal));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .carousel-container {
            position: relative;
        }
        .landing-page{
            overflow:hidden;
        }
        .guide__card {
            background: white;
            border-radius: var(--border-radius);
            overflow: hidden;
        }
        .place-items-center {
            place-items: center;
        }

        .div-round {
            height: 20px;
            width: 100vw;
            background: white;
            border-radius: 0 0 calc(var(--border-radius) * 4) calc(var(--border-radius) * 4);
            overflow: hidden;
            z-index: 2;
            position:absolute;
            top: -2px;
        }
        .div-round-rev {
            height: 20px;
            width: 100vw;
            background: white;
            border-radius: calc(var(--border-radius) * 4) calc(var(--border-radius) * 4) 0 0;
            overflow: hidden;
            z-index: 0;
            position:absolute;
            bottom: -2px;
        }

        .spacer {
            height: 1rem;
        }

        .relative {
            position: relative;
            place-self: center;
        }

        :global(.guide__card:first-of-type) {
            margin-left: 5rem !important;
        }

        :global(.card__container) {
            max-width: 100vw !important;
            margin-left: -5rem !important;
        }

        .contain-rel {
            position: relative;
            overflow: hidden;
        }

        :global(.testimonial .slider-container) {
            width: 100%;
        }

        .w-full {
            width: 100%;
        }

        @media (max-width: 1000px) {
            .icon {
                display: none;
            }
        }

        .video-bg{
            display:block;
        }

        .static-bg{
            display:none;
        }

        @media (max-width: 768px) {
            .video-bg{
                display:none;
            }
            :global(.card__container) {
                max-width: 100vw !important;
                margin-left: 0rem !important;
            }
            :global(.guide__card:first-of-type) {
                margin-left: 0rem !important;
            }
            .static-bg{
                display:block;
            }
            .relative {
                place-self: start;
                display: flex;
                gap: 2rem;
            }
        }
    `}</style>
    </div>;
};
