import React from "react";
import { HomePage } from "../components/pages/HomePage";
import { GetAllDatoImages, GetAllGuides, api, convertNumberedKeysToArrays, replaceImageValues } from "../components/helpers";
import Layout from "../components/layouts/HomeLayout";
import ParallaxHeroContext from "../components/ContextProviders/ParallaxHeroContext";
import { useCarousel } from "../components/hooks/useCarousel";
import { stripObjectKeys } from "./blog";
import Head from "next/head";
const content = require("../data/home-page.json");

export default function Page ({ ecosystem, content, user, busy, setMobileMenu, mobileMenuIsOpen, handleToast, isTransparent, guides }) {
    const contextFunctions = useCarousel();

    return <><Head>
        <title>{`${content.meta.title} | GoldRush - powered by Covalent`}</title>
        <meta name="description" content={content.meta.description} />
        <meta name="keywords" content={content.meta.keywords} />
        <meta property="og:description" content={content.meta.description} />
        <meta property="og:title" content={`${content.meta.title} ${content.category.length > 0 ? `| ${content.category}` : ""}| Covalent`} />
        <meta name="og:image" content={`https://og-generator-liart.vercel.app/api/param?title=${encodeURI((content.meta.title))}`} />
        <link
            rel="canonical"
            href={`https://www.goldrush.dev/`}
            key="canonical" />
    </Head>
    <ParallaxHeroContext.Provider value={contextFunctions}><Layout handleToast={handleToast} user={user} busy={busy} setMobileMenu={setMobileMenu} mobileMenuIsOpen={mobileMenuIsOpen} isTransparent={isTransparent}>
        <HomePage ecosystem={ecosystem} content={content} guides={guides} />
    </Layout></ParallaxHeroContext.Provider></>;
}

export async function getStaticProps() {
    const ecosystem = await api.get_all_ecosystems();
    const images = await GetAllDatoImages();
    const transformedContent = convertNumberedKeysToArrays(replaceImageValues(content, images));
    const ecosystemData = ecosystem.data.allEcosystems.filter((o) => o.showcase).map((item) => item.imgwhite.responsiveImage);
    const guides = await GetAllGuides();
    return {
        props: {
            component: "HomePage",
            ecosystem: ecosystemData,
            content: transformedContent,
            guides: guides.splice(0, 4).filter((x) => x._status === "published").map((guide) => stripObjectKeys(guide, ["bannerimagegraphic", "seo", "slug", "title"])),
        },
    };
}
